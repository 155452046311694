import React from 'react'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Styled Elements
import {
  TrialSignupWrapper,
  TrialSignupInnerWrapper,
  TrialSignupLogoLink,
  TrialSignupFormWrapper,
  TrialSignupFormInnerWrapper,
  TrialSignupFormContent,
} from './TrialSignup.elements'

// Components
import { TrialSignupForm } from 'views/layouts'

function TrialSignup() {
  return (
    <TrialSignupWrapper>
      <TrialSignupInnerWrapper>
        <TrialSignupFormWrapper>
          <TrialSignupFormInnerWrapper>
            <TrialSignupFormContent>
              <TrialSignupLogoLink>
                <OspreeLogoIcon />
              </TrialSignupLogoLink>
              <TrialSignupForm />
            </TrialSignupFormContent>
          </TrialSignupFormInnerWrapper>
        </TrialSignupFormWrapper>
      </TrialSignupInnerWrapper>
    </TrialSignupWrapper>
  )
}

// Default Props
TrialSignup.defaultProps = {}

// Proptypes Validation
TrialSignup.propTypes = {}

export default TrialSignup
