import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

// Constants
import { RECIPIENT_TYPE_FILTER, RECIPIENT_SORT_DATE_FILTER } from 'common/constants/filters'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useSearchRecipient } from 'core/hooks/api'

// Styled Elements
import { TableFilterFormWrapper } from '../Filter.elements'

// Components
import { SimpleSelect, Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function RecipientTableFilter(props) {
  // Destructure
  const { filters, actions } = props
  const { recipientDirectoryFilters } = filters
  const { sort, account_type_id } = recipientDirectoryFilters

  // Store Actions
  const { setShowHeaderLoader, setRecipientDirectoryFilters } = actions

  // States
  const [searchResults, setSearchResults] = useState([])
  const [csi] = useState(getCustomerStatus())

  // Hooks
  const { searchRecipient, recipientSearchData, isRecipientSearchLoading } = useSearchRecipient()
  const history = useHistory()

  // Functions
  // Filter Functions
  const handleTypeSelect = async (value) => {
    if (account_type_id !== value) {
      await setRecipientDirectoryFilters({
        ...recipientDirectoryFilters,
        account_type_id: Number.isNaN(+value) ? undefined : +value,
      })
    }
  }
  const handleSortSelect = async (value) => {
    if (sort !== value) {
      await setRecipientDirectoryFilters({ ...recipientDirectoryFilters, sort: value })
    }
  }
  // Search Functions
  const handleOnAutoCompleteSelect = (value) => {
    history.push(`/directory/recipients/details/${value}`)
  }
  const handleOnAutoCompleteInputChange = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchRecipient({ search_term: value })
  }, 500)

  // useEffect
  useEffect(() => {
    if (recipientSearchData) {
      setSearchResults(() =>
        recipientSearchData.map(({ reference_id, display_name, id }) => ({
          label: reference_id,
          subLabel: display_name,
          value: id,
        }))
      )
    }
    if (!recipientSearchData) {
      setSearchResults([])
    }
  }, [recipientSearchData])

  useEffect(() => {
    if (isRecipientSearchLoading) setShowHeaderLoader(true)
    else if (!isRecipientSearchLoading) setShowHeaderLoader(false)
  }, [isRecipientSearchLoading])

  return (
    <TableFilterFormWrapper>
      {csi === 1 && (
        <Search
          options={searchResults}
          placeholder="Search ID or Name..."
          onInputChange={handleOnAutoCompleteInputChange}
          onSelect={handleOnAutoCompleteSelect}
          isLoading={isRecipientSearchLoading}
          filter={
            <>
              <SimpleSelect
                label="Filter By: "
                placeholder="Type"
                options={RECIPIENT_TYPE_FILTER}
                onChange={handleTypeSelect}
                value={account_type_id}
              />
              <SimpleSelect
                label="Sort By: "
                options={RECIPIENT_SORT_DATE_FILTER}
                onChange={handleSortSelect}
                value={sort}
              />
            </>
          }
        />
      )}

      {csi !== 1 && <Search options={[]} placeholder="Search ID or Name..." disabled />}
    </TableFilterFormWrapper>
  )
}

// Default Props
RecipientTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
RecipientTableFilter.propTypes = {
  filters: PropTypes.shape({
    recipientDirectoryFilters: PropTypes.shape({
      sort: PropTypes.string,
      account_type_id: PropTypes.number,
      RECIPIENT_status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setRecipientDirectoryFilters: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientTableFilter)
