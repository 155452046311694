/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useRequestEmailVerification } from 'core/hooks/api'

// Styled Elements
import { FormWrapper, FormInputGroupItem, FormGroupWrapper, FormButtonWrapper } from './UserDetailsForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserDetailsForm(props) {
  // Destructure
  const { values, viewMode, formRef, actions } = props
  const { showAlert } = actions

  // Hooks
  const {
    requestEmailVerification,
    isRequestEmailVerificationLoading,
    isRequestEmailVerificationSuccess,
    isRequestEmailVerificationError,
  } = useRequestEmailVerification()

  // Internal State
  const [initialData, setInitialData] = useState()
  const [csi] = useState(getCustomerStatus())

  // Variables
  const validationSchema = Yup.object().shape({
    name_first: Yup.string(),
    name_last: Yup.string(),
    email: Yup.string().email(),
    phone: Yup.string(),

    role: Yup.string(),
    timezone: Yup.string(),
  })

  // UseEffects
  useEffect(() => {
    setInitialData(values)
  }, [])
  useEffect(() => {
    if (!isRequestEmailVerificationLoading) {
      if (isRequestEmailVerificationSuccess) {
        showAlert({ type: 'success', message: 'A link was sent to your email' })
      } else if (isRequestEmailVerificationError) {
        showAlert({ type: 'error', message: 'An error occured, please try again later.' })
      }
    }
  }, [isRequestEmailVerificationSuccess, isRequestEmailVerificationError])
  return (
    <FormWrapper>
      {initialData && (
        <Form formRef={formRef} initialValues={initialData} validationSchema={validationSchema}>
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="First Name" name="name_first" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Last Name" name="name_last" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField
                disabled={viewMode || csi !== 1}
                label={initialData?.is_verified ? 'Email (Verified)' : 'Email (Unverified)'}
                type="email"
                name="email"
              />
              <FormButtonWrapper>
                <Button
                  variant={isRequestEmailVerificationLoading ? 'outlined' : initialData?.is_verified ? 'outlined' : ''}
                  disabled={initialData?.is_verified || isRequestEmailVerificationLoading}
                  onClick={() => {
                    requestEmailVerification()
                  }}
                >
                  {isRequestEmailVerificationLoading
                    ? 'Loading...'
                    : initialData?.is_verified
                    ? 'Verified'
                    : 'Verify Email'}
                </Button>
              </FormButtonWrapper>
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled={viewMode} label="Phone Number" type="tel" name="phone" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled label="Role" name="role_desc" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField disabled label="Timezone" name="timezone_description" />
            </FormInputGroupItem>
          </FormGroupWrapper>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
UserDetailsForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    name_first: 'Rob',
    name_last: 'Smith',
    email: 'rerowe@core.com',
    phone: '+994502823424',
    role: 'Administrator',
    timezone: 'London, UK (GMT 0)',
  },
  viewMode: true,
}

// Proptypes Validation
UserDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string,
    timezone: PropTypes.string,
  }),
  viewMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm)
