import styled from 'styled-components'

export const SkeletonTextWrapper = styled.div`
  width: 300px;
  height: 20px;
  background: #474f5f;

  border-radius: 5px;

  // Conditional Properties
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}px;`};
`
