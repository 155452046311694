import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'
import UsersIcon from 'assets/images/users'
import BriefCaseIcon from 'assets/images/briefcase'

// Utils
import { getCustomerStatus, notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllRecipients } from 'core/hooks/api'

// Styled Elements
import { TableWrapper, DropdownItemInnerText } from './RecipientsTable.elements'

// Views
import {
  CardTable,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  EmptyTablePlaceholder,
  LoadingTablePlaceholder,
} from 'views/components'

import { RecipientsTableItem, RecipientTableFilter, DemoAccountsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RecipientsTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { recipientDirectoryFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const {
    setRecipientDirectoryFilters,
    setIsIndividualRecipientDrawerOpen,
    setIsEntityRecipientDrawerOpen,
    setToBeUpdatedRecipient,
    setIsPageTableUpdated,
    toggleUpgradeModal,
  } = actions

  // Variables
  const { sort, page, size, account_type_id, status_id } = recipientDirectoryFilters

  // States
  const [recipientsDataState, setRecipientsDataState] = useState({ items: [], total: 0, page: 1 })
  const [csi] = useState(getCustomerStatus())

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allRecipientsData, getAllRecipients, isAllRecipientsLoading } = useFetchAllRecipients()

  // Functions
  const fetchRecipients = () => {
    if (csi === 1) {
      getAllRecipients({
        biz_id: userCredentials.Business_ID,
        sort,
        size,
        page,
        account_type_id,
        status_id,
      })
    }
  }
  const notifyForPayment = () => {
    if (csi !== 1) {
      notifyPaymentRequired(toggleUpgradeModal)
    }
  }

  // UseEffects
  useEffect(() => {
    if (allRecipientsData) setRecipientsDataState(allRecipientsData)
  }, [allRecipientsData])

  useEffect(() => {
    if (recipientDirectoryFilters || isPageTableUpdated) {
      fetchRecipients()
      setIsPageTableUpdated(false)
    }
  }, [recipientDirectoryFilters, isPageTableUpdated])

  return (
    <TableWrapper>
      {csi === 1 && (
        <CardTable
          key={recipientsDataState?.page}
          totalItems={recipientsDataState.total}
          headerOptions={
            <UncontrolledDropdown direction="down">
              <DropdownToggle>
                <Button size="small" variant="outlined" startIcon={<PlusWhiteIcon />}>
                  Recipient
                </Button>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem
                  onClick={() => {
                    setToBeUpdatedRecipient(null)
                    setIsIndividualRecipientDrawerOpen(true)
                  }}
                >
                  <UsersIcon />
                  <DropdownItemInnerText>Individual Recipient</DropdownItemInnerText>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setToBeUpdatedRecipient(null)
                    setIsEntityRecipientDrawerOpen(true)
                  }}
                >
                  <BriefCaseIcon />
                  <DropdownItemInnerText>Entity Recipient</DropdownItemInnerText>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
          minWidth={900}
          filterComponents={<RecipientTableFilter />}
          tableFilters={recipientDirectoryFilters}
          setTableFilters={setRecipientDirectoryFilters}
        >
          {recipientsDataState.items.length > 0 && !isAllRecipientsLoading ? (
            recipientsDataState.items.map((recipientDetails) => (
              <RecipientsTableItem key={recipientDetails.id} recipientDetails={recipientDetails} />
            ))
          ) : (
            <>
              {isAllRecipientsLoading ? (
                <LoadingTablePlaceholder />
              ) : (
                <EmptyTablePlaceholder setFilter={setRecipientDirectoryFilters} />
              )}
              <div />
            </>
          )}
        </CardTable>
      )}

      {csi !== 1 && (
        <CardTable
          key={recipientsDataState?.page}
          totalItems={recipientsDataState.total}
          headerOptions={
            <UncontrolledDropdown direction="down">
              <DropdownToggle>
                <Button size="small" variant="outlined" startIcon={<PlusWhiteIcon />} onClick={notifyForPayment}>
                  Recipient
                </Button>
              </DropdownToggle>
            </UncontrolledDropdown>
          }
          minWidth={900}
          filterComponents={<RecipientTableFilter />}
          tableFilters={recipientDirectoryFilters}
          setTableFilters={setRecipientDirectoryFilters}
        >
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
        </CardTable>
      )}
    </TableWrapper>
  )
}

// Default Props
RecipientsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
RecipientsTable.propTypes = {
  filters: PropTypes.shape({
    recipientDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedRecipient: PropTypes.func,
    setRecipientDirectoryFilters: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientsTable)
