/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  Wrapper,
  Container,
  ColWrapper,
  RowWrapper,
  FlowWrapper,
  FlowContainer,
  FlowDetailsWrapper,
  TransferDetailsWrapper,
  ButtonWrapper,
  BlockContainer,
} from './DemoTravelRuleTableItemDetails.elements'

// Components
import { VerticalDotProgress, SkeletonText, Button } from 'views/components'

// Layouts
import { TravelRuleTableItemSubHeader } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DemoTravelRuleTableItemDetails(props) {
  // Destructure
  const { actions } = props

  const { toggleUpgradeModal } = actions

  // hooks
  const [page, setPage] = useState(0)
  const [formattedFlow] = useState([
    {
      leftLabel: '2024-11-13T03:09:33',
      rightLabel: 'Initiated',
      disabled: false,
      failed: false,
    },
    {
      leftLabel: null,
      rightLabel: 'Information Sent',
      disabled: false,
      failed: false,
    },
    {
      leftLabel: null,
      rightLabel: 'Pending Counterparty Action',
      disabled: false,
      failed: false,
    },
    {
      leftLabel: null,
      rightLabel: 'Counterparty Action',
      disabled: false,
      failed: false,
    },
    {
      leftLabel: null,
      rightLabel: 'Confirmed',
      disabled: false,
      failed: false,
    },
  ])

  return (
    <Wrapper>
      <Container>
        <ButtonWrapper>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              notifyPaymentRequired(toggleUpgradeModal)
            }}
          >
            Cancel
          </Button>
        </ButtonWrapper>

        <TravelRuleTableItemSubHeader page={page} setPage={setPage} />
        {page === 0 && (
          <FlowWrapper>
            <FlowContainer>
              <BlockContainer>
                <FlowDetailsWrapper>
                  <RowWrapper>
                    <Text>Date Initiated:</Text>
                    <SkeletonText width="210" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Transfer Number:</Text>
                    <SkeletonText width="290" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Asset: </Text>
                    <SkeletonText width="100" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Amount:</Text>
                    <SkeletonText width="180" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Amount USD:</Text>
                    <SkeletonText width="150" />
                  </RowWrapper>
                </FlowDetailsWrapper>
              </BlockContainer>
            </FlowContainer>
            <FlowContainer>
              <BlockContainer style={{ paddingBottom: '60px' }}>
                <VerticalDotProgress headers={formattedFlow} />
              </BlockContainer>
            </FlowContainer>
          </FlowWrapper>
        )}

        {page === 1 && (
          <TransferDetailsWrapper style={{ gridTemplateColumns: '1fr' }}>
            <RowWrapper>
              <ColWrapper
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
                }}
              >
                <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                  <Title>Beneficiary</Title>
                  <RowWrapper>
                    <Text>Account ID: </Text>
                    <SkeletonText width="180" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Name: </Text>
                    <SkeletonText width="240" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Wallet Address: </Text>
                    <SkeletonText width="280" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Physical Address: </Text>
                    <SkeletonText width="250" />
                  </RowWrapper>
                </BlockContainer>
                <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                  <Title>Beneficiary VASP</Title>
                  <RowWrapper>
                    <Text>Legal Name: </Text>
                    <SkeletonText width="240" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Website: </Text>
                    <SkeletonText width="280" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Legal Entity Identifier: </Text>
                    <SkeletonText width="180" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Registration Country: </Text>
                    <SkeletonText width="170" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Physical Address: </Text>
                    <SkeletonText width="230" />
                  </RowWrapper>
                </BlockContainer>

                <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                  <Title>Originator</Title>
                  <RowWrapper>
                    <Text>Account ID: </Text>
                    <SkeletonText width="180" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Name: </Text>
                    <SkeletonText width="240" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Legal Entity Identifier</Text>
                    <SkeletonText width="180" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Registration Country: </Text>
                    <SkeletonText width="150" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Wallet Address: </Text>
                    <SkeletonText width="280" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Physical Address: </Text>
                    <SkeletonText width="250" />
                  </RowWrapper>
                </BlockContainer>
                <BlockContainer style={{ height: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                  <Title>Originator VASP</Title>
                  <RowWrapper>
                    <Text>Legal Name: </Text>
                    <SkeletonText width="240" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Website: </Text>
                    <SkeletonText width="280" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Legal Entity Identifier: </Text>
                    <SkeletonText width="180" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Registration Country: </Text>
                    <SkeletonText width="170" />
                  </RowWrapper>
                  <RowWrapper>
                    <Text>Physical Address: </Text>
                    <SkeletonText width="230" />
                  </RowWrapper>
                </BlockContainer>
              </ColWrapper>
            </RowWrapper>
          </TransferDetailsWrapper>
        )}
      </Container>
    </Wrapper>
  )
}

// Default Props
DemoTravelRuleTableItemDetails.defaultProps = {
  actions: {},
}

// Proptypes Validation
DemoTravelRuleTableItemDetails.propTypes = {
  actions: PropTypes.shape({
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTravelRuleTableItemDetails)
