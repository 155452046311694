/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { LinkIcon, ExternalLinkIcon } from 'assets/images'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  RowWrapper,
  RowContainer,
  TextRowContainer,
  BlockContainer,
  FlowWrapper,
  ExternalLink,
  SkeletonContainer,
} from '../../DemoAddressesTableItemDetails.elements'

// Components
import { Button, SkeletonText } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function LinkedAccount(props) {
  // Destructure
  const { actions } = props

  // Store
  const { toggleUpgradeModal } = actions

  return (
    <FlowWrapper>
      <RowWrapper>
        <Title style={{ fontWeight: '600', fontSize: '15px', padding: '10px 0 5px 20px' }}>
          Linked Account Information:
        </Title>
      </RowWrapper>
      <RowWrapper>
        <BlockContainer>
          <TextRowContainer>
            <Text>Account ID: </Text>
            <Text>
              <ExternalLink
                onClick={() => {
                  notifyPaymentRequired(toggleUpgradeModal)
                }}
              >
                <SkeletonText width="80" height="16" />
                <ExternalLinkIcon />
              </ExternalLink>
            </Text>
          </TextRowContainer>
          <TextRowContainer>
            <Text>Account Type: </Text>
            <SkeletonText width="80" height="16" />
          </TextRowContainer>
          <TextRowContainer>
            <Text>Status: </Text>
            <SkeletonText width="100" height="16" />
          </TextRowContainer>
          <TextRowContainer>
            <Text>Email: </Text>
            <SkeletonText width="60" height="16" />
          </TextRowContainer>
        </BlockContainer>

        <BlockContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="100" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="190" height="18" />
          </TextRowContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="120" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="160" height="18" />
          </TextRowContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="80" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="150" height="18" />
          </TextRowContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="90" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="160" height="18" />
          </TextRowContainer>
        </BlockContainer>

        <BlockContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="50" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="190" height="18" />
          </TextRowContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="120" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="160" height="18" />
          </TextRowContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="80" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="140" height="18" />
          </TextRowContainer>
          <TextRowContainer>
            <SkeletonContainer>
              <SkeletonText width="50" height="18" /> :{' '}
            </SkeletonContainer>
            <SkeletonText width="190" height="18" />
          </TextRowContainer>
        </BlockContainer>
      </RowWrapper>
      <RowWrapper>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            size="small"
            endIcon={<LinkIcon />}
            onClick={() => {
              notifyPaymentRequired(toggleUpgradeModal)
            }}
          >
            Link
          </Button>
        </RowContainer>
      </RowWrapper>
    </FlowWrapper>
  )
}

// Default Props
LinkedAccount.defaultProps = {
  actions: {},
  address_data: {},
}

// Proptypes Validation
LinkedAccount.propTypes = {
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  accountDataProp: PropTypes.shape({
    id: PropTypes.string,
    reference_id: PropTypes.string,
    business_id: PropTypes.string,
    account_type_id: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    date_of_birth: PropTypes.string,
    nationality_code: PropTypes.string,
    display_country: PropTypes.string,
    name_legal: PropTypes.string,
    name_business: PropTypes.string,
    inc_country_code: PropTypes.string,
    inc_date: PropTypes.string,
    email: PropTypes.string,
    address_street_name: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
    primary_activity_code: PropTypes.number,
    account_risk_value: PropTypes.number,
    sanction_id: PropTypes.number,
    pep_id: PropTypes.number,
    adverse_media_id: PropTypes.number,
    comments: PropTypes.string,
    status_id: PropTypes.number,
    created: PropTypes.string,
    last_modified: PropTypes.string,
    is_recipient: PropTypes.bool,
  }),
  address_data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedAccount)
