import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeModule: '',
  activeCase: '',
  activePage: '',
  activeInvestigationModule: '',
  showHeaderLoader: false,
  infoModalDetails: {
    title: '',
    content: '',
    loading: false,
  },
  notifyVaspDetails: {
    vasp_id: '',
    vasp_name: '',
  },
  rejectVaspDetails: {
    vasp_id: '',
    vasp_name: '',
  },
  updateTransferModalStatus: {
    confirmed: false,
  },
  updateTransferModalData: {
    title: '',
    content: '',
  },

  isDeleteAddressModalOpen: false,
  isDeleteAddressNoteModalOpen: false,
  isDeleteTransactionModalOpen: false,
  isDeleteTransactionNoteModalOpen: false,
  isChooseKYCUploadModalOpen: false,
  isCasesManagementFilterModalOpen: false,
  isWhiteListingFilterModalOpen: false,
  isUploadCaseModalOpen: false,
  isAddressHistoryModalOpen: false,
  isAddressReportModalOpen: false,
  isTransactionHistoryModalOpen: false,
  isTransactionReportModalOpen: false,
  isTransferReportModalOpen: false,
  isInfoModalOpen: false,
  isNotifyVaspModalOpen: false,
  isRejectVaspModalOpen: false,
  isUpdateTransferModalOpen: false,
  isTransactionDetailsModalOpen: false,
  isExploreAccountModalOpen: false,
  isUpgradeModalOpen: false,

  isNewCaseCreated: false,
  isRecordUpdated: false,
  isPageTableUpdated: false,
  isProfileUpdated: false,
  isNotifUpdated: false,

  isAppSidebarDrawerOpen: false,
  isAssignToCaseDrawerOpen: false,
  isCreateIndividualCaseDrawerOpen: false,
  isCreateEntityCaseDrawerOpen: false,

  isIndividualAccountDrawerOpen: false,
  isEntityAccountDrawerOpen: false,
  isIndividualRecipientDrawerOpen: false,
  isEntityRecipientDrawerOpen: false,
  isVaspDrawerOpen: false,
  isTransferDrawerOpen: false,
  isLinkAddressDrawerOpen: false,
  isLinkTransactionDrawerOpen: false,
  isUserDrawerOpen: false,
}

const store = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveModule: (state, { payload }) => {
      state.activeModule = payload
    },
    setActiveCase: (state, { payload }) => {
      state.activeCase = payload
    },
    setActivePage: (state, { payload }) => {
      state.activePage = payload
    },
    setInfoModalDetails: (state, { payload }) => {
      if (payload) {
        const { title, content, loading } = payload
        state.infoModalDetails = { title, content, loading }
      } else {
        state.infoModalDetails = { title: '', content: '', loading: false }
      }
    },
    setActiveInvestigationModule: (state, { payload }) => {
      state.activeInvestigationModule = payload
    },
    setShowHeaderLoader: (state, { payload }) => {
      state.showHeaderLoader = payload
    },
    setNotifyVaspDetails: (state, { payload }) => {
      state.notifyVaspDetails = payload || {
        vasp_id: '',
        vasp_name: '',
      }
    },
    setRejectVaspDetails: (state, { payload }) => {
      state.rejectVaspDetails = payload || {
        vasp_id: '',
        vasp_name: '',
      }
    },
    setUpdateTransferModalStatus: (state, { payload }) => {
      state.updateTransferModalStatus = payload || {
        confirmed: false,
      }
    },
    setUpdateTransferModalData: (state, { payload }) => {
      state.updateTransferModalData = payload || {
        title: '',
        content: '',
      }
    },
    //*
    toggleDeleteAddressModal: (state) => {
      state.isDeleteAddressModalOpen = !state.isDeleteAddressModalOpen
    },
    toggleDeleteAddressNoteModal: (state) => {
      state.isDeleteAddressNoteModalOpen = !state.isDeleteAddressNoteModalOpen
    },
    toggleDeleteTransactionModal: (state) => {
      state.isDeleteTransactionModalOpen = !state.isDeleteTransactionModalOpen
    },
    toggleDeleteTransactionNoteModal: (state) => {
      state.isDeleteTransactionNoteModalOpen = !state.isDeleteTransactionNoteModalOpen
    },
    toggleChooseKYCUploadModal: (state) => {
      state.isChooseKYCUploadModalOpen = !state.isChooseKYCUploadModalOpen
    },
    toggleCasesManagementFilterModal: (state) => {
      state.isCasesManagementFilterModalOpen = !state.isCasesManagementFilterModalOpen
    },
    toggleWhiteListingFilterModalOpen: (state) => {
      state.isWhiteListingFilterModalOpen = !state.isWhiteListingFilterModalOpen
    },
    toggleUploadCaseModal: (state) => {
      state.isUploadCaseModalOpen = !state.isUploadCaseModalOpen
    },
    toggleAddressHistoryModal: (state) => {
      state.isAddressHistoryModalOpen = !state.isAddressHistoryModalOpen
    },
    toggleAddressReportModal: (state) => {
      state.isAddressReportModalOpen = !state.isAddressReportModalOpen
    },
    toggleTransactionHistoryModal: (state) => {
      state.isTransactionHistoryModalOpen = !state.isTransactionHistoryModalOpen
    },
    toggleTransactionReportModal: (state) => {
      state.isTransactionReportModalOpen = !state.isTransactionReportModalOpen
    },
    toggleTransferReportModal: (state) => {
      state.isTransferReportModalOpen = !state.isTransferReportModalOpen
    },
    toggleInfoModal: (state, { payload }) => {
      if (payload !== undefined || payload !== null) {
        state.isInfoModalOpen = payload
      } else {
        state.isInfoModalOpen = !state.isInfoModalOpen
      }
    },
    toggleNotifyVaspModal: (state) => {
      state.isNotifyVaspModalOpen = !state.isNotifyVaspModalOpen
    },
    toggleRejectVaspModal: (state) => {
      state.isRejectVaspModalOpen = !state.isRejectVaspModalOpen
    },
    toggleUpdateTransferModal: (state) => {
      state.isUpdateTransferModalOpen = !state.isUpdateTransferModalOpen
    },
    toggleTransactionDetailsModalOpen: (state) => {
      state.isTransactionDetailsModalOpen = !state.isTransactionDetailsModalOpen
    },
    toggleExploreAccountModalOpen: (state) => {
      state.isExploreAccountModalOpen = !state.isExploreAccountModalOpen
    },
    toggleUpgradeModal: (state) => {
      state.isUpgradeModalOpen = !state.isUpgradeModalOpen
    },
    //*
    setNewCaseCreated: (state, { payload }) => {
      state.isNewCaseCreated = payload
    },
    setIsRecordUpdated: (state, { payload }) => {
      state.isRecordUpdated = payload
    },
    setIsPageTableUpdated: (state, { payload }) => {
      state.isPageTableUpdated = payload
    },
    setIsProfileUpdated: (state, { payload }) => {
      state.isProfileUpdated = payload
    },
    setIsNotifUpdated: (state, { payload }) => {
      state.isNotifUpdated = payload
    },
    //*
    openAppSidebarDrawer: (state) => {
      state.isAppSidebarDrawerOpen = true
    },
    closeAppSidebarDrawer: (state) => {
      state.isAppSidebarDrawerOpen = false
    },
    openAssignToCaseDrawer: (state) => {
      state.isAssignToCaseDrawerOpen = true
    },
    closeAssignToCaseDrawer: (state) => {
      state.isAssignToCaseDrawerOpen = false
    },
    openCreateIndividualCaseDrawer: (state) => {
      state.isCreateIndividualCaseDrawerOpen = true
    },
    closeCreateIndividualCaseDrawer: (state) => {
      state.isCreateIndividualCaseDrawerOpen = false
    },
    openCreateEntityCaseDrawer: (state) => {
      state.isCreateEntityCaseDrawerOpen = true
    },
    closeCreateEntityCaseDrawer: (state) => {
      state.isCreateEntityCaseDrawerOpen = false
    },
    //*
    setIsIndividualAccountDrawerOpen: (state, { payload }) => {
      state.isIndividualAccountDrawerOpen = payload || false
    },
    setIsEntityAccountDrawerOpen: (state, { payload }) => {
      state.isEntityAccountDrawerOpen = payload || false
    },
    setIsIndividualRecipientDrawerOpen: (state, { payload }) => {
      state.isIndividualRecipientDrawerOpen = payload || false
    },
    setIsEntityRecipientDrawerOpen: (state, { payload }) => {
      state.isEntityRecipientDrawerOpen = payload || false
    },
    setIsVaspDrawerOpen: (state, { payload }) => {
      state.isVaspDrawerOpen = payload || false
    },
    setIsTransferDrawerOpen: (state, { payload }) => {
      state.isTransferDrawerOpen = payload || false
    },
    setIsLinkAddressDrawerOpen: (state, { payload }) => {
      state.isLinkAddressDrawerOpen = payload || false
    },
    setIsLinkTransactionDrawerOpen: (state, { payload }) => {
      state.isLinkTransactionDrawerOpen = payload || false
    },
    setIsUserDrawerOpen: (state, { payload }) => {
      state.isUserDrawerOpen = payload || false
    },
  },
})

export default store
