import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useGetUser, useUpdateProfile } from 'core/hooks/api'

// Styled Elements
import {
  Wrapper,
  DetailsContainer,
  FooterBtnWrapper,
  RowWrapper,
  RowContainer,
  ColWrapper,
  Title,
  Text,
  ButtonWrapper,
} from './UserDetails.elements'

// Components
import { LoadingTablePlaceholder, Container, Button, Select } from 'views/components'

// layouts
import { UserDetailsForm, App, Header, Sidebar, Content, PageWrapper, UserDetailsSubHeader } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function UserDetails(props) {
  // Destructure
  const { actions } = props
  const { user_id } = useParams()

  // store actions
  const { setShowHeaderLoader, showAlert } = actions

  // refs
  const formRef = useRef()

  // hooks
  const { getUser, getUserData, isGetUserLoading } = useGetUser()
  const { updateProfile, updateProfileData, isUpdateProfileLoading, isUpdateProfileSuccess, isUpdateProfileError } =
    useUpdateProfile()

  // state
  const [userDataState, setUserDataState] = useState()
  const [viewMode, setViewMode] = useState(true)
  const [page, setPage] = useState(0)
  const [csi] = useState(getCustomerStatus())

  // functions
  const fetchData = () => {
    getUser({ user_id })
  }
  const handleSave = () => {
    setViewMode(true)
    const newPayload = formRef.current.values
    setUserDataState({ ...userDataState, ...newPayload })
    updateProfile({ user_id, data: newPayload })
  }
  const handle2faSave = (value) => {
    updateProfile({ user_id, data: { ...userDataState, has_2fa: value } })
  }
  const handleCancel = () => {
    setViewMode(true)
    if (updateProfileData) {
      setUserDataState(updateProfileData)
    } else if (getUserData) {
      setUserDataState(getUserData)
    }
  }
  const handleLoadingUpdateChange = () => {
    if (isUpdateProfileLoading) {
      setShowHeaderLoader(true)
      setUserDataState()
    }
    if (!isUpdateProfileLoading) {
      if (updateProfileData) {
        setUserDataState(updateProfileData)
      }
      if (isUpdateProfileError) {
        showAlert({ type: 'error', message: 'An error occured, please refresh the page.' })
      }
      if (isUpdateProfileSuccess) {
        showAlert({ type: 'success', message: 'Successfully updated record.' })
      }
      setShowHeaderLoader(false)
    }
  }
  const handleLoadingUser = () => {
    if (isGetUserLoading) setShowHeaderLoader(true)
    if (!isGetUserLoading) setShowHeaderLoader(false)
  }

  // useEffect
  useEffect(() => fetchData(), [])
  useEffect(() => {
    if (getUserData) setUserDataState(getUserData)
  }, [getUserData])
  useEffect(() => handleLoadingUser(), [isGetUserLoading])
  useEffect(() => handleLoadingUpdateChange(), [isUpdateProfileLoading])

  return (
    <App>
      <Sidebar />
      <Header pageTitle="User Profile" />
      <Content>
        <PageWrapper>
          <Container>
            <Wrapper>
              <UserDetailsSubHeader page={page} setPage={setPage} />
              {isUpdateProfileLoading && <LoadingTablePlaceholder />}
              {page === 0 && !isGetUserLoading && userDataState && (
                <DetailsContainer>
                  {!isUpdateProfileLoading && userDataState && (
                    <UserDetailsForm viewMode={viewMode} formRef={formRef} values={userDataState} />
                  )}
                  {viewMode === true && (
                    <FooterBtnWrapper>
                      <Button color="primary" onClick={() => setViewMode(false)}>
                        Edit
                      </Button>
                    </FooterBtnWrapper>
                  )}
                  {viewMode === false && (
                    <FooterBtnWrapper>
                      <Button onClick={handleCancel} variant="outlined">
                        Cancel
                      </Button>
                      <Button onClick={handleSave}>save</Button>
                    </FooterBtnWrapper>
                  )}
                </DetailsContainer>
              )}
              {page === 1 && !isGetUserLoading && userDataState && (
                <DetailsContainer style={{ marginTop: '-20px' }}>
                  <RowWrapper>
                    <ColWrapper>
                      <Title>Enable Two Factor Authentication</Title>
                      <Text>
                        {!userDataState?.is_verified && 'Email needs to be verified.'} A login link will be sent to your
                        email every login attempt.
                      </Text>
                    </ColWrapper>
                    <RowContainer>
                      <Select
                        value={userDataState?.has_2fa}
                        placeholder={userDataState?.has_2fa ? 'On' : 'Off'}
                        onChange={(value) => {
                          handle2faSave(value)
                        }}
                        disabled={!userDataState?.is_verified || csi !== 1}
                        options={[
                          { value: true, label: 'On' },
                          { value: false, label: 'Off' },
                        ]}
                      />
                    </RowContainer>
                  </RowWrapper>
                  <RowWrapper>
                    <ColWrapper>
                      <Title>Password</Title>
                      <Text>Update your password</Text>
                    </ColWrapper>
                    <RowContainer>
                      <ButtonWrapper href="/update_password">
                        <Button fullWidth>Update</Button>
                      </ButtonWrapper>
                    </RowContainer>
                  </RowWrapper>
                </DetailsContainer>
              )}
            </Wrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
UserDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
UserDetails.propTypes = {
  filters: PropTypes.shape({
    userDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setUserDirectoryFilters: PropTypes.func,
    setIsIndividualUserDrawerOpen: PropTypes.func,
    setIsEntityUserDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
