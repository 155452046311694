import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`
export const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  gap: 10px;
`

export const Container = styled.div``
export const FlowWrapper = styled.div``
export const FlowContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  width: 100%;
`

export const FlowDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 8px;
  max-width: 470px;
`
export const RowWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const GridRowContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
`
export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`
export const TextRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`
export const GroupRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-color: ${({ theme }) => theme.colors.lightGray};
  border-style: solid;
  border-width: 0;
`

export const BlockContainer = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  transition: 0.3s;
  /* &:hover {
    background-color: ${({ theme }) => `${theme.colors.gray}10`};
  } */

  & > div:last-child {
    border: none;
    padding-bottom: 0;
  }
`

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  border-color: ${({ theme }) => theme.colors.lightGray};
  border-style: solid;
  border-width: 0;
`
export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  & > div:last-child {
    border: none;
    padding-bottom: 0;
  }
`
export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const ColoredText = styled.span`
  margin: 0;
  padding: 5px 15px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
  background-color: ${({ theme }) => `${theme.colors.gray}30`};
  border: 1px solid ${({ theme, color }) => (color === 'darkGray' ? theme.colors.darkGray : theme.colors.lightGray)};
  border-radius: 8px;
`
export const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
  text-transform: uppercase;
  margin-bottom: 10px;
`
export const TransferDetailsWrapper = styled.div`
  padding: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
  gap: 60px;
`
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  font-size: 20px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 100%;
`
export const RiskContainer = styled.div`
  padding: 20px 20px 0 20px;

  & #rsSubTitle {
    color: ${({ theme }) => theme.colors.gray};
  }
`
export const LineBreak = styled.hr`
  width: 100%;
  height: 2px !important;

  color: ${({ theme }) => theme.colors.gray};
  margin: 20px 0;
`
export const InfoTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
  text-transform: uppercase;
  margin-bottom: -7px;

  font-weight: 300;
  font-size: 10px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`
export const ExternalLink = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  cursor: pointer;

  & svg {
    height: 100%;
    max-height: 15px;
  }
`

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
