export const getCustomerStatus = () => {
  const token = localStorage.getItem('userCredentials')
  if (!token) return null

  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = atob(base64)
    const credentials = JSON.parse(jsonPayload)

    if (credentials?.csi !== undefined && credentials?.csi !== null) {
      return credentials?.csi
    }
    return null
  } catch (error) {
    return null
  }
}

export const notifyPaymentRequired = (toggleUpgradeModal) => {
  toggleUpgradeModal(true)
}
