import styled from 'styled-components'

// Library Components
import { Modal } from 'reactstrap'

export const ModalWrapper = styled(Modal)`
  padding: 0;
  color: ${({ theme }) => theme.colors.darkGray};

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin: 0 auto;

  & .modal-content {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => `${theme.colors.gray}80`};
    border-radius: 10px;
  }
`
