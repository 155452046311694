import { useMutation } from 'react-query'

// Services
import { SignupTrial } from 'services/api/Auth'

export default () => {
  const {
    mutate: signupTrial,
    data: signupTrialData,
    error: signupTrialError,
    isLoading: isSignupTrialLoading,
    isSuccess: isSignupTrialSuccess,
    isError: isSignupTrialError,
  } = useMutation(SignupTrial)

  return {
    signupTrial,
    signupTrialData,
    signupTrialError,
    isSignupTrialLoading,
    isSignupTrialSuccess,
    isSignupTrialError,
  }
}
