import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  addressHistoryDetails: {
    address_id: '',
    address: '',
    chain: '',
  },
  addressReportDetails: {
    address_id: '',
    address: '',
    chain: '',
  },
  addressLinkDetails: {
    link_id: '',
    link_type: '',
  },
  addressLinkData: {
    account_id: '',
  },
  addressRemove: {
    address_id: '',
  },
  addressDetails: {
    address_id: '',
    address: '',
    chain: '',
    integration_id: '',
  },
  scannedAddress: {
    address: '',
    chain: '',
    symbol: '',
    symbol_icon: '',
    financial_data: {
      provider_name: '',
      balance: 0,
      balance_usd: 0,
      spent: 0,
      spent_usd: 0,
      received: 0,
      received_usd: 0,
      transaction_count: 0,
      latest_activity: '',
      tokens: [],
    },
    risk_data: {
      provider_name: '',
      risk_score: 0,
      risk_descriptors: [''],
      vasp_name_legal: '',
      vasp_name_business: '',
      vasp_website: '',
      vasp_category: '',
      api_result_id: '',
    },
    request_tz: '',
  },
  scannedAddressDetails: {},
  addressChains: null,
  defaultAddressProvider: {
    // RISK: {
    //   RDCFI: {
    //     BTC: {},
    //     ETH: {},
    //   },
    // },
    // FINANCIAL: {
    //   FDBLM: {
    //     BTC: {},
    //     ETH: {},
    //   },
    // },
  },
}

const store = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddressHistoryDetails: (state, { payload }) => {
      const { address_id, address, chain } = payload
      const payloadToAppend = { address_id, address, chain }
      state.addressHistoryDetails = payloadToAppend
    },
    setAddressReportDetails: (state, { payload }) => {
      const { address_id, address, chain } = payload
      const payloadToAppend = { address_id, address, chain }
      state.addressReportDetails = payloadToAppend
    },
    setAddressLinkDetails: (state, { payload }) => {
      // const { address_id, address, chain } = payload
      // const payloadToAppend = { address_id, address, chain }
      state.addressLinkDetails = payload
    },
    setAddressLinkData: (state, { payload }) => {
      // const { address_id, address, chain } = payload
      // const payloadToAppend = { address_id, address, chain }
      state.addressLinkData = payload
    },
    setAddressRemove: (state, { payload }) => {
      const { address_id } = payload
      const payloadToAppend = { address_id }
      state.addressRemove = payloadToAppend
    },
    setAddressDetails: (state, { payload }) => {
      const { address_id, address, chain } = payload
      const payloadToAppend = { address_id, address, chain }
      state.addressDetails = payloadToAppend
    },
    setScannedAddress: (state, { payload }) => {
      if (payload) {
        state.scannedAddress = payload
      } else {
        state.scannedAddress = null
      }
    },
    setScannedAddressDetails: (state, { payload }) => {
      if (payload) {
        state.scannedAddressDetails = payload
      } else {
        state.scannedAddressDetails = null
      }
    },
    setAddressChains: (state, { payload }) => {
      if (payload) {
        state.addressChains = payload
      } else {
        state.addressChains = null
      }
    },
    setDefaultAddressProvider: (state, { payload }) => {
      if (payload) {
        state.defaultAddressProvider = payload
      } else {
        state.defaultAddressProvider = null
      }
    },
  },
})

export default store
