/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Constants
import {
  TRAVELRULE_STATUS_FILTER,
  // TRAVELRULE_ASSET_FILTER,
  TRAVELRULE_SORT,
  TRAVELRULE_DIRECTION_FILTER,
} from 'common/constants/filters'

// Store
import { actions } from 'core/store'

// Utils
import { getCustomerStatus, notifyPaymentRequired } from 'common/utils/customerValidation'

// Hooks
import { useSearchTransfer } from 'core/hooks/api'

// Styled Elements
import { TableFilterFormWrapper } from '../Filter.elements'

// Components
import { SimpleSelect, Search, CustomDateRangePicker } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function TravelRuleTableFilter(props) {
  // Destructure
  const { onTransferSearch, setIsTransferLoading, filters, actions, address } = props
  const { travelRuleFilters } = filters
  const { sort, transfer_status_id, asset_code, start_date, end_date, direction_id } = travelRuleFilters

  // Store Actions
  const { setShowHeaderLoader, setTravelRuleFilters, toggleUpgradeModal } = actions
  const { addressChains } = address

  // Hooks
  const { transferSearchData, searchTransfer, isTransferSearchLoading } = useSearchTransfer()
  const [availableChains, setAvailableChains] = useState()
  const [csi] = useState(getCustomerStatus())

  // Functions
  // Filter Functions
  const handleDateSelect = async ({ start, end }) => {
    await setTravelRuleFilters({
      ...travelRuleFilters,
      start_date: start,
      end_date: end,
      page: 1,
    })
  }
  const handleAssetSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, asset_code: value })
  }
  const handleDirectionSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, direction_id: value })
  }
  const handleStatusSelect = async (value) => {
    await setTravelRuleFilters({
      ...travelRuleFilters,
      transfer_status_id: Number.isNaN(+value) ? undefined : +value,
    })
  }
  const handleSortSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, sort: value })
  }
  // Search Functions
  const handleOnAutoCompleteInputChange = debounce((value) => {
    if (!value) {
      return onTransferSearch()
    }
    if (value) {
      return searchTransfer({ search_term: value })
    }
  }, 500)
  function handleAvailableChains() {
    const providers = { ...addressChains?.financial }
    let allChains = {}
    Object.keys(providers).forEach((item) => {
      if (item === 'FDBDM') {
        allChains = { ...allChains, ...providers[item] }
      }
    })
    setAvailableChains(allChains)
  }

  // useEffect
  useEffect(() => {
    if (transferSearchData) onTransferSearch(transferSearchData)
    if (!transferSearchData) onTransferSearch([])
  }, [transferSearchData])
  useEffect(() => {
    handleAvailableChains()
  }, [addressChains])

  useEffect(() => {
    if (isTransferSearchLoading) {
      setIsTransferLoading(true)
      setShowHeaderLoader(true)
    } else if (!isTransferSearchLoading) {
      setIsTransferLoading(false)
      setShowHeaderLoader(false)
    }
  }, [isTransferSearchLoading])

  return (
    <TableFilterFormWrapper>
      {csi === 1 && (
        <Search
          disabledOptions
          placeholder="Search Name..."
          onInputChange={handleOnAutoCompleteInputChange}
          isLoading={isTransferSearchLoading}
          filter={
            <>
              <CustomDateRangePicker
                id="travelruleDateFilter"
                key={start_date}
                onApply={handleDateSelect}
                start={start_date}
                end={end_date}
                style={{ minWidth: '270px' }}
                simplifiedDate
              />
              <SimpleSelect
                label="Direction: "
                placeholder="All"
                options={TRAVELRULE_DIRECTION_FILTER}
                onChange={handleDirectionSelect}
                value={direction_id}
              />
              {availableChains && (
                <SimpleSelect
                  id="travelRuleAssetSort"
                  label="Asset: "
                  placeholder="All"
                  options={Object.keys(availableChains).map((item) => ({
                    value: availableChains[item]?.symbol,
                    label: availableChains[item]?.symbol,
                  }))}
                  onChange={handleAssetSelect}
                  value={asset_code}
                />
              )}
              <SimpleSelect
                label="Status: "
                placeholder="All"
                options={TRAVELRULE_STATUS_FILTER}
                onChange={handleStatusSelect}
                value={transfer_status_id}
              />
              <SimpleSelect
                id="travelRuleSort"
                label="Sort By: "
                placeholder="Amount or Date"
                options={TRAVELRULE_SORT}
                onChange={handleSortSelect}
                value={sort[1]}
              />
            </>
          }
        />
      )}
      {csi !== 1 && (
        <Search
          disabledOptions
          placeholder="Search Name..."
          onInputChange={() => {}}
          onClick={() => {
            notifyPaymentRequired(toggleUpgradeModal)
          }}
          isLoading={isTransferSearchLoading}
        />
      )}
    </TableFilterFormWrapper>
  )
}

// Default Props
TravelRuleTableFilter.defaultProps = {
  onTransferSearch: () => {},
  filters: {},
  actions: {},
}

// Proptypes Validation
TravelRuleTableFilter.propTypes = {
  onTransferSearch: PropTypes.func,
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.shape({
      sort: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      direction_id: PropTypes.string,
      asset_code: PropTypes.number,
      transfer_status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
  }),
  setIsTransferLoading: PropTypes.func,
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTableFilter)
