import styled from 'styled-components'

export const LoginFormWrapper = styled.div`
  & button {
    min-height: 56px;
  }
`

export const SuccessMsg = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.blue};
  margin: 0 0 30px 0;
`

export const LoginFormLabel = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const FormInputGroupWrapper = styled.div`
  margin: 0px 0px 30px 0px;
`

export const FormInputGroupItem = styled.div`
  margin: 0px 0px 20px 0px;
  & input {
    background-color: ${({ theme }) => theme.colors.white};
  }
  & label {
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 3px 10px;
  }
`
export const FormLink = styled.a`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;

  margin: 0 0 -20px 0;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray};
  margin: 30px 0;
`
