/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Styled Elements
import {
  SidebarWrapper,
  SidebarBrand,
  SidebarBrandLogo,
  SidebarMenuNav,
  SidebarMenuNavList,
  SidebarMenuNavItem,
  SidebarMenuNavLink,
  SidebarMenuNavText,
  SidebarHelpWrapper,
  SidebarHelpContainer,
  HelpMenuWrapper,
  HelpMenuContainer,
  Divider,
  SidebarMenuNavLinkDemo,
} from './Sidebar.elements'

// assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'
import BlockchainAnalyticsIcon from 'assets/images/blockchainAnalytics'
import CaseManagementIcon from 'assets/images/caseManagement'
import DirectoryIcon from 'assets/images/directory'
import PlusDocumentIcon from 'assets/images/plusDocument'
import TravelRuleIcon from 'assets/images/travelRule'
import SettingsIcon from 'assets/images/settings'
import MarkerIcon from 'assets/images/marker'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function Sidebar(props) {
  // Destructure
  const { actions, children } = props
  const { toggleUpgradeModal } = actions

  // Local State
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [userCredentials] = useLocalStorage('userCredentials', {})

  const wrapperRef = useRef()

  // Closing options on click away
  useOutsideAlerter(wrapperRef, setIsHelpOpen)
  return (
    <SidebarWrapper>
      <SidebarMenuNav>
        <SidebarMenuNavList>
          <SidebarBrand>
            <NavLink to="/">
              <SidebarBrandLogo>
                <OspreeLogoIcon />
              </SidebarBrandLogo>
            </NavLink>
          </SidebarBrand>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/blockchain-analytics">
              <BlockchainAnalyticsIcon />
              <SidebarMenuNavText>Blockchain Analytics</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/travelrule">
              <TravelRuleIcon />
              <SidebarMenuNavText>Travel Rule</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/directory">
              <DirectoryIcon />
              <SidebarMenuNavText>Directory</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/reports">
              <PlusDocumentIcon />
              <SidebarMenuNavText>Reports</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLinkDemo onClick={() => notifyPaymentRequired(toggleUpgradeModal)}>
              <CaseManagementIcon />
              <SidebarMenuNavText>Case Management</SidebarMenuNavText>
            </SidebarMenuNavLinkDemo>
          </SidebarMenuNavItem>

          {userCredentials?.role_id === 0 && (
            <SidebarMenuNavItem>
              <SidebarMenuNavLink activeClassName="active" to="/admin/vaspnotification">
                <SettingsIcon />
                <SidebarMenuNavText>Vasp Notification</SidebarMenuNavText>
              </SidebarMenuNavLink>
            </SidebarMenuNavItem>
          )}
          {children}
        </SidebarMenuNavList>

        <SidebarMenuNavList ref={wrapperRef}>
          <SidebarHelpWrapper>
            <SidebarHelpContainer active={isHelpOpen} onClick={() => setIsHelpOpen(!isHelpOpen)}>
              <MarkerIcon />
              <SidebarMenuNavText>Get Help</SidebarMenuNavText>
            </SidebarHelpContainer>

            <HelpMenuWrapper active={isHelpOpen}>
              <HelpMenuContainer
                active={isHelpOpen}
                onClick={() => {
                  window.open('https://docs.ospree.io', '_blank')
                }}
              >
                Learn More
              </HelpMenuContainer>
              <Divider />
              <HelpMenuContainer
                active={isHelpOpen}
                onClick={() => {
                  window.open(
                    'https://calendly.com/ospree-meetings/ospree-demo?embed_domain=ospree.io&embed_type=PopupText&background_color=434b59&text_color=ffffff&primary_color=00cacd&month=2024-06',
                    '_blank'
                  )
                }}
              >
                Book a call
              </HelpMenuContainer>
              <Divider />
              <HelpMenuContainer active={isHelpOpen} onClick={() => window.open('https://ospree.io/help/', '_blank')}>
                Visit knowledge base
              </HelpMenuContainer>
            </HelpMenuWrapper>
          </SidebarHelpWrapper>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink exact to="/settings">
              <SettingsIcon />
              <SidebarMenuNavText>Settings</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
        </SidebarMenuNavList>
      </SidebarMenuNav>
    </SidebarWrapper>
  )
}

// Default Props
Sidebar.defaultProps = {
  children: '',
  toggleUpgradeModal: () => {},
}

// Proptypes Validation
Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  toggleUpgradeModal: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
