/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import backgroundImage from 'assets/images/abstractbg.png'
import { CheckIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Views
import { Modal, Button } from 'views/components'

// Styled Elements
import {
  ModalBodyWrapper,
  ContentWrapper,
  ChecklistContainer,
  ChecklistItem,
  Title,
  Text,
  Link,
  ButtonWrapper,
} from './UpgradeModal.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UpgradeModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isUpgradeModalOpen } = ui

  // Store Actions
  const { toggleUpgradeModal } = actions

  // Functions
  const toggleModal = () => {
    toggleUpgradeModal()
  }

  return (
    <Modal isOpen={isUpgradeModalOpen} toggle={toggleModal} style={{ maxWidth: '800px' }}>
      <ModalBodyWrapper style={{ backgroundImage: `url(${backgroundImage})`, borderRadius: '10px' }}>
        <ContentWrapper>
          <Title>Simplify compliance in one-click</Title>
          <Text>
            Upgrade to one of our plans to streamline compliance management and unlock powerful tools designed for
            digital asset businesses.
          </Text>
          <ChecklistContainer>
            <ChecklistItem>
              <CheckIcon />
              <Text>Multiple users</Text>
            </ChecklistItem>

            <ChecklistItem>
              <CheckIcon />
              <Text>Automated Travel Rule compliance</Text>
            </ChecklistItem>

            <ChecklistItem>
              <CheckIcon />
              <Text>Blockchain Analytics Integration</Text>
            </ChecklistItem>

            <ChecklistItem>
              <CheckIcon />
              <Text>Custom Reporting Hub</Text>
            </ChecklistItem>

            <ChecklistItem>
              <CheckIcon />
              <Text>Accounts Directory Access</Text>
            </ChecklistItem>

            <ChecklistItem>
              <CheckIcon />
              <Text>API Support</Text>
            </ChecklistItem>
          </ChecklistContainer>

          <ButtonWrapper>
            <Link href="https://www.ospree.io/contact/sales#contactsales" target="_blank">
              <Button variant="outlined" fullWidth>
                Contact Sales
              </Button>
            </Link>
            <Link href="https://www.ospree.io/contact/sales#calendly" target="_blank">
              <Button fullWidth>Upgrade</Button>
            </Link>
          </ButtonWrapper>
        </ContentWrapper>
      </ModalBodyWrapper>
    </Modal>
  )
}

// Default Props
UpgradeModal.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
UpgradeModal.propTypes = {
  ui: PropTypes.shape({
    isUpgradeModalOpen: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    toggleUpgradeModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeModal)
