import { useMutation } from 'react-query'

// Services
import { ConfirmTrialAccount } from 'services/api/Auth'

export default () => {
  const {
    mutate: confirmTrialAccount,
    data: confirmTrialAccountData,
    error: confirmTrialAccountError,
    isLoading: isConfirmTrialAccountLoading,
    isSuccess: isConfirmTrialAccountSuccess,
    isError: isConfirmTrialAccountError,
  } = useMutation(ConfirmTrialAccount)

  return {
    confirmTrialAccount,
    confirmTrialAccountData,
    confirmTrialAccountError,
    isConfirmTrialAccountLoading,
    isConfirmTrialAccountSuccess,
    isConfirmTrialAccountError,
  }
}
