import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Utils
import { notifyPaymentRequired, getCustomerStatus } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAllTransfers, useGetAddressChains } from 'core/hooks/api'

// Styled Elements
import { TravelRuleTableWrapper } from './TravelRuleTable.elements'

// Views
import { Button, AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

// Layouts
import { TravelRuleTableItem, TravelRuleTableFilter, DemoTravelRuleTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TravelRuleTable(props) {
  // Destructure
  const { actions, filters, ui, address } = props
  const { addressChains } = address
  const { travelRuleFilters } = filters
  const { isPageTableUpdated } = ui
  const {
    setAddressChains,
    setIsTransferDrawerOpen,
    setTravelRuleFilters,
    setIsPageTableUpdated,
    toggleTransferReportModal,
    toggleUpgradeModal,
  } = actions

  // Hooks
  const [transfersData, setTransfersData] = useState({ items: [], total: 0 })
  const { getAllTransfers, allTransferData, isAllTransferLoading } = useFetchAllTransfers()
  const { getAddressChains, getAddressChainsData } = useGetAddressChains()

  // State
  const [isTransferLoading, setIsTransferLoading] = useState(false)
  const [csi] = useState(getCustomerStatus())

  // Functions
  const fetchData = async () => {
    if (csi === 1) {
      getAllTransfers(travelRuleFilters)
    }
    if (csi !== 1) {
      setTransfersData({
        items: [
          {
            id: '06734186-de55-7893-8000-b7b034b01bbc',
            amount: 1,
            amount_usd: 87904.85,
            asset_code: 'BTC',
            dti: '4H95J0R2X',
            dti_name_short: ['BTC', 'XBT'],
            dti_name_long: 'Bitcoin',
            protocol_code: 'flow',
            in_address_id: 'a7fc4513-c1ee-44f9-a8e2-f9dd42135ce9',
            out_address_id: 'f6e5abb4-d4c2-45ea-8b77-a14c4c08e4b5',
            in_account_id: '066365f9-1566-7e38-8000-7c6bcf942375',
            out_account_id: '066365f9-2264-7dec-8000-c572ceaff3fb',
            in_business_id: '062e956f-1474-726f-8000-a56ebb70eaef',
            out_business_id: '062e9574-500c-7e13-8000-de2af9440e11',
            in_vasp_id: 'AYLWIZ',
            out_vasp_id: 'QTJKZF',
            out_account_type_id: 1,
            transfer_status_id: 0,
            transfer_status_desc: 'Initiated',
            transfer_status_count: 5,
            reference_id: 'QA-TEST-06512',
            tx_hash: null,
            in_record_id: '06734186-deae-7d9c-8000-1cc67fbd785b',
            out_record_id: '06734186-deba-7835-8000-f288bdb0a4d9',
            in_record_name: 'Daytons Jamie Denesik',
            out_record_name: 'Kaelyn Jules Labadie',
            in_account_name: 'Daytons Jamie Denesik',
            out_account_name: 'Kaelyn Jules Labadie',
            created: '2024-11-13T03:09:33.923783+00:00',
            last_modified: '2024-11-13T03:09:33.923783+00:00',
            direction_id: 2,
          },
        ],
        total: 1,
      })
    }
  }
  const handleOnTransferSearch = (data) => {
    if (!data) {
      return getAllTransfers(travelRuleFilters)
    }
    setTransfersData({ items: data, total: 0 })
  }
  function handleAddressChains() {
    if (!getAddressChainsData || Object?.keys(getAddressChainsData || {})?.length < 1) return
    // !Idk why but this is causing some bugs but we need this to optimize the process
    // if (addressChains && defaultAddressProvider) return
    const newAddressChains = {}
    const newDefaultAddressProvider = {}
    getAddressChainsData.forEach((item) => {
      // Destructure with default values
      const {
        data_type = '',
        provider_code = '',
        symbol = '',
        is_user_default = false,
        integration_id = '',
      } = item || {}

      // Initialize the data_type and provider_code if they don't exist
      if (!newAddressChains[data_type]) newAddressChains[data_type] = {}
      if (!newAddressChains[data_type][provider_code]) newAddressChains[data_type][provider_code] = {}
      // Insert the item directly
      newAddressChains[data_type][provider_code][symbol] = item
      // Handle the default address provider
      if (is_user_default && !newDefaultAddressProvider[data_type]) {
        newDefaultAddressProvider[data_type] = { provider_code, integration_id, data_type }
      }
    })

    // Sorting by symbol using .sort()
    Object.keys(newAddressChains).forEach((data_type) => {
      Object.keys(newAddressChains[data_type]).forEach((provider_code) => {
        // Get the symbols and sort them
        const symbols = Object.keys(newAddressChains[data_type][provider_code])
        const sortedSymbols = symbols.sort() // Sort the symbols
        // Create a new object to store sorted entries
        const sortedPartner = {}
        sortedSymbols.forEach((symbol) => {
          sortedPartner[symbol] = newAddressChains[data_type][provider_code][symbol]
        })
        // Replace the unsorted partner object with the sorted one
        newAddressChains[data_type][provider_code] = sortedPartner
      })
    })
    setAddressChains(newAddressChains)
  }

  // UseEffects
  useEffect(() => {
    if (!addressChains || Object?.keys(addressChains || {})?.length < 1) getAddressChains()
  }, [])
  useEffect(() => handleAddressChains(), [getAddressChainsData])
  useEffect(() => fetchData(), [travelRuleFilters])
  useEffect(() => allTransferData && setTransfersData(allTransferData), [allTransferData])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchData()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])

  return (
    <TravelRuleTableWrapper>
      <AccordionTable
        minWidth={700}
        totalItems={transfersData.total}
        tableFilters={travelRuleFilters}
        setTableFilters={setTravelRuleFilters}
        filterComponents={
          <TravelRuleTableFilter
            setIsTransferLoading={setIsTransferLoading}
            onTransferSearch={handleOnTransferSearch}
          />
        }
        headerOptions={
          <>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                if (csi === 1) {
                  toggleTransferReportModal()
                }
                if (csi !== 1) {
                  notifyPaymentRequired(toggleUpgradeModal)
                }
              }}
            >
              Report
            </Button>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                if (csi === 1) {
                  setIsTransferDrawerOpen(true)
                }
                if (csi !== 1) {
                  notifyPaymentRequired(toggleUpgradeModal)
                }
              }}
            >
              Transfer
            </Button>
          </>
        }
      >
        {transfersData.items.length > 0 &&
          !isAllTransferLoading &&
          !isTransferLoading &&
          transfersData.items.map((singleData) => (
            <TravelRuleTableItem key={singleData.transfer_id} data={singleData} />
          ))}
        {(isAllTransferLoading || isTransferLoading) && <LoadingTablePlaceholder />}
        {transfersData?.items?.length < 1 && !isAllTransferLoading && !isTransferLoading && (
          <EmptyTablePlaceholder setFilter={setTravelRuleFilters} />
        )}
        {csi !== 1 && (
          <>
            <DemoTravelRuleTableItem progress="20%" />
            <DemoTravelRuleTableItem progress="40%" />
            <DemoTravelRuleTableItem progress="20%" />
            <DemoTravelRuleTableItem progress="60%" />
            <DemoTravelRuleTableItem progress="80%" />
          </>
        )}
      </AccordionTable>
    </TravelRuleTableWrapper>
  )
}

// Default Props
TravelRuleTable.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
TravelRuleTable.propTypes = {
  actions: PropTypes.shape({
    setAddressChains: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
    setIsTransferDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleTransferReportModal: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.instanceOf(Object),
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTable)
