export const bannedEmailDomains = [
  'gmail.com',
  'outlook.com',
  'yahoo.com',
  'protonmail.com',
  'zoho.com',
  'aol.com',
  'gmx.com',
  'mail.com',
  'notabene.id',
  'sumsub.com',
  '21analytics.ch',
]
