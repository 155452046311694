import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

// Styled Elements
import {
  Chip,
  Text,
  Label,
  Title,
  SubTitle,
  TableColWrapper,
  TableColContainer,
  TableRowWrapper,
  TableRowContainer,
  StatusContainer,
} from './VaspsTableItem.elements'

// Views
import { CardTableItem } from 'views/components'

function VaspsTableItem(props) {
  // Destructure
  const { vaspDetails } = props
  const { external_id, name_business, name_legal, id, email_contact, website, created, vasp_id } = vaspDetails

  // State
  const [cardName, setCardName] = useState({})

  // Functions

  // useEffect
  useEffect(() => {
    const name = name_business || name_legal
    const nameArr = name.split(' ')
    const firstNameLength = Math.floor(nameArr.length / 2)
    const firstName = []
    for (let i = 0; i < firstNameLength; i++) {
      firstName.push(nameArr[i])
    }
    nameArr.splice(0, firstNameLength)
    setCardName({
      initials: name[0] + name[1],
      fullname: [firstName.join(' '), nameArr.join(' ')],
    })
  }, [external_id])

  return (
    <NavLink to={`/directory/vasps/details/${id}`}>
      <CardTableItem key={external_id}>
        <TableRowWrapper>
          <Title>{cardName.initials || ''}</Title>
          <TableColContainer>
            <SubTitle>{cardName.fullname && cardName.fullname[0]}</SubTitle>
            <SubTitle>{cardName.fullname && cardName.fullname[1]}</SubTitle>
          </TableColContainer>
        </TableRowWrapper>

        <TableColWrapper>
          {vaspDetails?.customer_status_id !== null && vaspDetails?.customer_status_id !== undefined && (
            <TableRowContainer>
              <Label>Status: </Label>
              <StatusContainer>
                <Chip customer_status_id={vaspDetails.customer_status_id}>
                  {vaspDetails?.customer_status_id === -1 && 'Rejected'}
                </Chip>
              </StatusContainer>
            </TableRowContainer>
          )}
          <TableRowContainer>
            <Label>Vasp ID: </Label>
            <Text>{vasp_id || 'NA'}</Text>
          </TableRowContainer>

          <TableRowContainer>
            <Label>Website: </Label>
            <Text>{website || 'NA'}</Text>
          </TableRowContainer>

          <TableRowContainer>
            <Label>Email: </Label>
            <Text>{email_contact || 'NA'}</Text>
          </TableRowContainer>

          <TableRowContainer>
            <Label>Latest update: </Label>
            <Text>{moment(created).format('MMMM DD YYYY, h:mm:ss')}</Text>
          </TableRowContainer>
        </TableColWrapper>
      </CardTableItem>
    </NavLink>
  )
}

// Default Props
VaspsTableItem.defaultProps = {}

// Proptypes Validation
VaspsTableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  vaspDetails: PropTypes.any,
}

export default VaspsTableItem
