import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Utils
import { getCustomerStatus, notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllMidProspects } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './RequestedVaspsTable.elements'

// Views
import { CardTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { VaspsTableItem, RequestedVaspTableFilter, DemoVaspsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RequestedVaspsTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { vaspDirectoryFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const { setVaspDirectoryFilters, setIsPageTableUpdated, toggleUpgradeModal } = actions

  // Variables
  const { sort, page, size } = vaspDirectoryFilters

  // States
  const [midProspectDataState, setMidProspectsDataState] = useState({ items: [], total: 0, page: 1 })
  const [csi] = useState(getCustomerStatus())

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allMidProspectsData, getAllMidProspects, isAllMidProspectsLoading } = useFetchAllMidProspects()

  // Functions
  const fetchVasps = () => {
    if (csi === 1) {
      getAllMidProspects({
        biz_id: userCredentials.Business_ID,
        sort,
        page,
        size,
      })
    }
  }
  const notifyForPayment = () => {
    if (csi !== 1) {
      notifyPaymentRequired(toggleUpgradeModal)
    }
  }

  // UseEffects
  useEffect(() => {
    if (allMidProspectsData) setMidProspectsDataState(allMidProspectsData)
  }, [allMidProspectsData])

  useEffect(() => {
    if (vaspDirectoryFilters || isPageTableUpdated) {
      fetchVasps()
      setIsPageTableUpdated(false)
    }
  }, [vaspDirectoryFilters, isPageTableUpdated])

  return (
    <TableWrapper>
      {csi === 1 && (
        <CardTable
          key={midProspectDataState?.page}
          totalItems={midProspectDataState.total}
          minWidth={900}
          filterComponents={<RequestedVaspTableFilter />}
          tableFilters={vaspDirectoryFilters}
          setTableFilters={setVaspDirectoryFilters}
        >
          {midProspectDataState.items.length > 0 && !isAllMidProspectsLoading ? (
            midProspectDataState.items.map((vaspDetails) => (
              <VaspsTableItem key={vaspDetails.id} vaspDetails={vaspDetails} />
            ))
          ) : (
            <>
              {isAllMidProspectsLoading ? (
                <LoadingTablePlaceholder />
              ) : (
                <EmptyTablePlaceholder setFilter={setVaspDirectoryFilters} />
              )}
              <div />
            </>
          )}
        </CardTable>
      )}
      {csi !== 1 && (
        <CardTable
          key={midProspectDataState?.page}
          totalItems={midProspectDataState.total}
          minWidth={900}
          filterComponents={<RequestedVaspTableFilter />}
          tableFilters={vaspDirectoryFilters}
          setTableFilters={setVaspDirectoryFilters}
        >
          <DemoVaspsTableItem onClick={notifyForPayment} />
          <DemoVaspsTableItem onClick={notifyForPayment} />
          <DemoVaspsTableItem onClick={notifyForPayment} />
          <DemoVaspsTableItem onClick={notifyForPayment} />
          <DemoVaspsTableItem onClick={notifyForPayment} />
          <DemoVaspsTableItem onClick={notifyForPayment} />
          <DemoVaspsTableItem onClick={notifyForPayment} />
          <DemoVaspsTableItem onClick={notifyForPayment} />
        </CardTable>
      )}
    </TableWrapper>
  )
}

// Default Props
RequestedVaspsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
RequestedVaspsTable.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestedVaspsTable)
