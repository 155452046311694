/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Assets
import SentIcon from 'assets/images/sent'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableColWrapper,
  TableRowContainer,
  TableText,
  TableSubText,
  TablePanelWrapper,
} from './DemoTravelRuleTableItem.elements'

// Views
import { AccordionTableItem, ProgressBar, SkeletonText } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function DemoTravelRuleTableItem(props) {
  // Destructure
  const { actions, progress } = props
  const { toggleUpgradeModal } = actions

  return (
    <AccordionTableItem
      onClick={() => notifyPaymentRequired(toggleUpgradeModal)}
      setIsExpanded={() => {}}
      button={
        <TableButtonWrapper>
          <TableRowWrapper isExpanded={false}>
            <TableRowContainer>
              <SentIcon />
              <TableColWrapper>
                <TableText>
                  <SkeletonText width="160" />
                </TableText>
                <TableSubText>
                  Sent | <SkeletonText height="10" width="90" borderRadius="3" />
                </TableSubText>
              </TableColWrapper>
            </TableRowContainer>

            <TableRowContainer>
              <SkeletonText width="140" />
            </TableRowContainer>
          </TableRowWrapper>

          <TableRowWrapper isExpanded={false}>
            <TableRowContainer>
              <SkeletonText width="70" />
              <ProgressBar width={progress || '20%'} />
            </TableRowContainer>

            <TableRowContainer>
              <TableColWrapper>
                <SkeletonText width="60" />
                <SkeletonText width="80" height="10" borderRadius="3" style={{ marginTop: 2 }} />
              </TableColWrapper>
            </TableRowContainer>
          </TableRowWrapper>
        </TableButtonWrapper>
      }
      panel={<TablePanelWrapper />}
    />
  )
}

// Default Props
DemoTravelRuleTableItem.defaultProps = {
  actions: {},
  progress: '',
}

// Proptypes Validation
DemoTravelRuleTableItem.propTypes = {
  actions: PropTypes.shape({
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  progress: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTravelRuleTableItem)
