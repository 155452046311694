import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'
import UsersIcon from 'assets/images/users'
import BriefCaseIcon from 'assets/images/briefcase'

// Utils
import { getCustomerStatus, notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllAccounts } from 'core/hooks/api'

// Styled Elements
import { TableWrapper, DropdownItemInnerText } from './AccountsTable.elements'

// Views
import {
  CardTable,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  EmptyTablePlaceholder,
  LoadingTablePlaceholder,
} from 'views/components'

import { AccountsTableItem, AccountTableFilter, DemoAccountsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AccountsTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { accountDirectoryFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const {
    setAccountDirectoryFilters,
    setIsIndividualAccountDrawerOpen,
    setIsEntityAccountDrawerOpen,
    setToBeUpdatedAccount,
    setIsPageTableUpdated,
    toggleUpgradeModal,
  } = actions

  // Variables
  const { sort, page, size, account_type_id, status_id } = accountDirectoryFilters

  // States
  const [accountsDataState, setAccountsDataState] = useState({ items: [], total: 0, page: 1 })
  const [csi] = useState(getCustomerStatus())

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allAccountsData, getAllAccounts, isAllAccountsLoading } = useFetchAllAccounts()

  // Functions
  const fetchAccounts = () => {
    if (csi === 1) {
      getAllAccounts({
        biz_id: userCredentials.Business_ID,
        sort,
        size,
        page,
        account_type_id,
        status_id,
      })
    }
  }
  const notifyForPayment = () => {
    if (csi !== 1) {
      notifyPaymentRequired(toggleUpgradeModal)
    }
  }

  // UseEffects
  useEffect(() => {
    if (allAccountsData) setAccountsDataState(allAccountsData)
  }, [allAccountsData])

  useEffect(() => {
    if (accountDirectoryFilters || isPageTableUpdated) {
      fetchAccounts()
      setIsPageTableUpdated(false)
    }
  }, [accountDirectoryFilters, isPageTableUpdated])

  return (
    <TableWrapper>
      {csi === 1 && (
        <CardTable
          key={accountsDataState?.page}
          totalItems={accountsDataState.total}
          headerOptions={
            <UncontrolledDropdown direction="down">
              <DropdownToggle>
                <Button size="small" variant="outlined" startIcon={<PlusWhiteIcon />}>
                  Account
                </Button>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem
                  onClick={() => {
                    setToBeUpdatedAccount(null)
                    setIsIndividualAccountDrawerOpen(true)
                  }}
                >
                  <UsersIcon />
                  <DropdownItemInnerText>Individual Account</DropdownItemInnerText>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setToBeUpdatedAccount(null)
                    setIsEntityAccountDrawerOpen(true)
                  }}
                >
                  <BriefCaseIcon />
                  <DropdownItemInnerText>Entity Account</DropdownItemInnerText>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
          minWidth={900}
          filterComponents={<AccountTableFilter />}
          tableFilters={accountDirectoryFilters}
          setTableFilters={setAccountDirectoryFilters}
        >
          {accountsDataState.items.length > 0 && !isAllAccountsLoading ? (
            accountsDataState.items.map((accountDetails) => (
              <AccountsTableItem key={accountDetails.id} accountDetails={accountDetails} />
            ))
          ) : (
            <>
              {isAllAccountsLoading ? (
                <LoadingTablePlaceholder />
              ) : (
                <EmptyTablePlaceholder setFilter={setAccountDirectoryFilters} />
              )}
              <div />
            </>
          )}
        </CardTable>
      )}

      {csi !== 1 && (
        <CardTable
          key={accountsDataState?.page}
          totalItems={accountsDataState.total}
          headerOptions={
            <UncontrolledDropdown direction="down">
              <DropdownToggle>
                <Button size="small" variant="outlined" startIcon={<PlusWhiteIcon />} onClick={notifyForPayment}>
                  Account
                </Button>
              </DropdownToggle>
            </UncontrolledDropdown>
          }
          minWidth={900}
          filterComponents={<AccountTableFilter />}
          tableFilters={accountDirectoryFilters}
          setTableFilters={setAccountDirectoryFilters}
        >
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={1} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
          <DemoAccountsTableItem account_type_id={2} onClick={notifyForPayment} />
        </CardTable>
      )}
    </TableWrapper>
  )
}

// Default Props
AccountsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
AccountsTable.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsTable)
