import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

// Constants
import { ACCOUNT_STATUS_FILTER, ACCOUNT_TYPE_FILTER, ACCOUNT_SORT_DATE_FILTER } from 'common/constants/filters'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Hooks
import { useSearchAccount } from 'core/hooks/api'

// Styled Elements
import { TableFilterFormWrapper } from '../Filter.elements'

// Components
import { SimpleSelect, Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function AccountTableFilter(props) {
  // Destructure
  const { filters, actions } = props
  const { accountDirectoryFilters } = filters
  const { sort, account_type_id, status_id } = accountDirectoryFilters

  // Store Actions
  const { setShowHeaderLoader, setAccountDirectoryFilters } = actions

  // States
  const [searchResults, setSearchResults] = useState([])
  const [csi] = useState(getCustomerStatus())

  // Hooks
  const { searchAccount, accountSearchData, isAccountSearchLoading } = useSearchAccount()
  const history = useHistory()

  // Functions
  // Filter Functions
  const handleStatusSelect = async (value) => {
    if (status_id !== value) {
      await setAccountDirectoryFilters({
        ...accountDirectoryFilters,
        status_id: Number.isNaN(+value) ? undefined : +value,
      })
    }
  }
  const handleTypeSelect = async (value) => {
    if (account_type_id !== value) {
      await setAccountDirectoryFilters({
        ...accountDirectoryFilters,
        account_type_id: Number.isNaN(+value) ? undefined : +value,
      })
    }
  }
  const handleSortSelect = async (value) => {
    if (sort !== value) {
      await setAccountDirectoryFilters({ ...accountDirectoryFilters, sort: value })
    }
  }
  // Search Functions
  const handleOnAutoCompleteSelect = (value) => {
    history.push(`/directory/accounts/details/${value}`)
  }
  const handleOnAutoCompleteInputChange = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchAccount({ search_term: value })
  }, 500)

  // useEffect
  useEffect(() => {
    if (accountSearchData) {
      setSearchResults(() =>
        accountSearchData.map(({ reference_id, display_name, id }) => ({
          label: reference_id,
          subLabel: display_name,
          value: id,
        }))
      )
    }
    if (!accountSearchData) {
      setSearchResults([])
    }
  }, [accountSearchData])

  useEffect(() => {
    if (isAccountSearchLoading) setShowHeaderLoader(true)
    else if (!isAccountSearchLoading) setShowHeaderLoader(false)
  }, [isAccountSearchLoading])

  return (
    <TableFilterFormWrapper>
      {csi === 1 && (
        <Search
          options={searchResults}
          placeholder="Search ID or Name..."
          onInputChange={handleOnAutoCompleteInputChange}
          onSelect={handleOnAutoCompleteSelect}
          isLoading={isAccountSearchLoading}
          filter={
            <>
              <SimpleSelect
                label="Filter By: "
                placeholder="Type"
                options={ACCOUNT_TYPE_FILTER}
                onChange={handleTypeSelect}
                value={account_type_id}
              />
              <SimpleSelect
                label="Filter By: "
                placeholder="Status"
                options={ACCOUNT_STATUS_FILTER}
                onChange={handleStatusSelect}
                value={status_id}
              />
              <SimpleSelect
                label="Sort By: "
                options={ACCOUNT_SORT_DATE_FILTER}
                onChange={handleSortSelect}
                value={sort}
              />
            </>
          }
        />
      )}
      {csi !== 1 && <Search options={[]} placeholder="Search ID or Name..." disabled />}
    </TableFilterFormWrapper>
  )
}

// Default Props
AccountTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
AccountTableFilter.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.shape({
      sort: PropTypes.string,
      account_type_id: PropTypes.number,
      status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTableFilter)
