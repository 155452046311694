import React, { useState } from 'react'

// Styled Elements
import {
  Label,
  TableColWrapper,
  TableColContainer,
  TableRowWrapper,
  TableRowContainer,
} from './DemoVaspsTableItem.elements'

// Views
import { CardTableItem, SkeletonText } from 'views/components'

function DemoVaspsTableItem(props) {
  const { ...rest } = props
  return (
    <CardTableItem {...rest}>
      <TableRowWrapper>
        <SkeletonText height="40" width="70" style={{ marginRight: '4px' }} />
        <TableColContainer>
          <LocalSkeletonText />
          <LocalSkeletonText />
        </TableColContainer>
      </TableRowWrapper>

      <TableColWrapper>
        <TableRowContainer>
          <Label>Vasp ID: </Label>
          <LocalSkeletonText />
        </TableRowContainer>

        <TableRowContainer>
          <Label>Website: </Label>
          <LocalSkeletonText />
        </TableRowContainer>

        <TableRowContainer>
          <Label>Email: </Label>
          <LocalSkeletonText />
        </TableRowContainer>

        <TableRowContainer>
          <Label>Latest update: </Label>
          <LocalSkeletonText />
        </TableRowContainer>
      </TableColWrapper>
    </CardTableItem>
  )
}

// Default Props
DemoVaspsTableItem.defaultProps = {}

// Proptypes Validation
DemoVaspsTableItem.propTypes = {}

export default DemoVaspsTableItem

function LocalSkeletonText() {
  const [num] = useState(`${Math.floor(Math.random() * 120 + 30)}`)
  return <SkeletonText width={num} height="16" borderRadius="4" />
}
