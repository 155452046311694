import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

// Constants
import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Wrapper, GeneralWrapper, RowWrapper, RowContainer, ColWrapper, Title, Text } from './Settings.elements'

// Components
import {
  Container,
  //  Checkbox,
  Select,
  Button,
} from 'views/components'

// layouts
import { App, Header, Sidebar, Content, PageWrapper, SettingsSubHeader, UsersTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function Settings(props) {
  const { actions } = props

  // destructuring
  const { toggleInfoModal, setInfoModalDetails, setIsUserDrawerOpen } = actions

  // hooks
  const [page, setPage] = useState(0)
  const { search } = useLocation()

  const [theme, setTheme] = useLocalStorage('theme', 'light')
  const [currentUser] = useLocalStorage('userCredentials', {})

  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }
  const handlePageQuery = () => {
    const urlStr = search.split('=')
    if (urlStr.length === 2) setPage(Number(urlStr[1]))
  }
  useEffect(() => handlePageQuery(), [search])
  return (
    <App>
      <Sidebar />
      <Header pageTitle="Settings" />
      <Content>
        <PageWrapper>
          <Container>
            <Wrapper>
              <SettingsSubHeader page={page} setPage={setPage} />
              {page === 0 && (
                <GeneralWrapper>
                  {/* <RowWrapper>
                    <ColWrapper>
                      <Title>Notification - Blockchain Analytics </Title>
                      <Text>Notifications about address or transaction risk scores </Text>
                    </ColWrapper>
                    <RowContainer>
                      <Select
                        value={theme}
                        placeholder="Off"
                        options={[
                          { value: 'on', label: 'On', disabled: true },
                          { value: 'off', label: 'Off' },
                        ]}
                      />
                    </RowContainer>
                  </RowWrapper>

                  <RowWrapper>
                    <ColWrapper>
                      <Title>Notification - Detection Engine</Title>
                      <Text>Notifications about detection engine alerts and policies.</Text>
                    </ColWrapper>
                    <RowContainer>
                      <Select
                        value={theme}
                        placeholder="Off"
                        options={[
                          { value: 'on', label: 'On', disabled: true },
                          { value: 'off', label: 'Off' },
                        ]}
                      />
                    </RowContainer>
                  </RowWrapper>

                  <RowWrapper>
                    <ColWrapper>
                      <Title>Notification - Travel Rule</Title>
                      <Text>Notifications about travel rule transfers.</Text>
                    </ColWrapper>
                    <RowContainer>
                      <Select
                        value={theme}
                        placeholder="Off"
                        options={[
                          { value: 'on', label: 'On', disabled: true },
                          { value: 'off', label: 'Off' },
                        ]}
                      />
                    </RowContainer>
                  </RowWrapper> */}

                  <RowWrapper>
                    <ColWrapper>
                      <Title>Theme</Title>
                      <Text>Select the theme.</Text>
                    </ColWrapper>
                    <RowContainer>
                      <Select
                        value={theme}
                        placeholder="Select"
                        options={[
                          { value: 'light', label: 'Light', disabled: true },
                          { value: 'dark', label: 'Dark' },
                        ]}
                        onChange={async (value) => {
                          await setTheme(value)
                          window.location.reload()
                        }}
                      />
                    </RowContainer>
                  </RowWrapper>
                  <RowWrapper>
                    <ColWrapper>
                      <Title>Plan</Title>
                      <Text>Change plan.</Text>
                    </ColWrapper>
                    <RowContainer>
                      <Select
                        value=""
                        placeholder="Trial"
                        options={[
                          { value: 'accelerate', label: 'Accelerate', disabled: true },
                          { value: 'scale', label: 'Scale', disabled: true },
                          { value: 'global', label: 'Global', disabled: true },
                        ]}
                      />
                    </RowContainer>
                  </RowWrapper>

                  <RowWrapper>
                    <ColWrapper>
                      <Title>Terms use</Title>
                      <Text>Review the terms and conditions for using Ospree&apos;s platform.</Text>
                    </ColWrapper>
                    <Button onClick={() => window.open(EXTERNAL_URLS.TERMS_OF_SERVICE, '_blank')}>Read </Button>
                  </RowWrapper>

                  <RowWrapper>
                    <ColWrapper>
                      <Title>Privacy Policy</Title>
                      <Text>
                        Review our Privacy Policy to understand what data we collect, why we collect it, and how it is
                        used.
                      </Text>
                    </ColWrapper>
                    <Button onClick={() => window.open(EXTERNAL_URLS.PRIVACY_POLICY, '_blank')}>Read </Button>
                  </RowWrapper>

                  <RowWrapper>
                    <ColWrapper>
                      <Title>Version</Title>
                      <Text>Ospree 2.0.0</Text>
                    </ColWrapper>
                    <Button
                      onClick={() =>
                        handleToggleInfo(
                          'Ospree 2.0.0',
                          'Ospree | Version 2.0.0 | ©2023 Ospree Pte. Ltd. | All Rights Reserved.'
                        )
                      }
                    >
                      Read{' '}
                    </Button>
                  </RowWrapper>
                </GeneralWrapper>
              )}
              {/* {page === 1 && (
                <GeneralWrapper>
                </GeneralWrapper>
              )} */}
              {/* {currentUser?.role_id === 1 && page === 1 && (
                <GeneralWrapper>
                  <RowWrapper>
                    <ColWrapper>
                      <Title>Created Users</Title>
                      <Text>
                        The list of users under the company. Managers can create users and update their email and roles.
                      </Text>
                    </ColWrapper>
                    <Button
                      onClick={() => {
                        setIsUserDrawerOpen(true)
                      }}
                    >
                      Create User
                    </Button>
                  </RowWrapper>
                </GeneralWrapper>
              )} */}
            </Wrapper>
            {/* {currentUser?.role_id === 1 && page === 1 && (
              <GeneralWrapper>
                <UsersTable />
              </GeneralWrapper>
            )} */}
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Settings.defaultProps = {
  actions: {},
}

// Proptypes Validation
Settings.propTypes = {
  actions: PropTypes.shape({
    setIsUserDrawerOpen: PropTypes.func,
    setIsIndividualUserDrawerOpen: PropTypes.func,
    setIsEntityUserDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
