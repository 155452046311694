/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Styled Elements
import { Wrapper, SearchBarWrapper, SearchInputWrapper, SearchBarContainer } from './DemoTransactionSearchBar.elements'

// Views
import { TextField } from 'views/components'

// Assets
import { ScanIcon } from 'assets/images'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function DemoTransactionSearchBar(props) {
  // props
  const { actions } = props

  // destructure
  // Store Actions
  const { toggleUpgradeModal } = actions

  return (
    <Wrapper>
      <SearchBarWrapper>
        <SearchBarContainer>
          <SearchInputWrapper>
            <ScanIcon />
            <TextField
              placeholder="Screen a transaction hash"
              onClick={() => {
                notifyPaymentRequired(toggleUpgradeModal)
              }}
            />
          </SearchInputWrapper>
        </SearchBarContainer>
      </SearchBarWrapper>
    </Wrapper>
  )
}

// Default Props
DemoTransactionSearchBar.defaultProps = {
  actions: {},
}

// Proptypes Validation
DemoTransactionSearchBar.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setScannedAddress: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
    defaultAddressProvider: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoTransactionSearchBar)
