import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { SkeletonTextWrapper } from './SkeletonText.elements'

function SkeletonText(props) {
  // Destructure
  const { width, height, borderRadius, children, ...rest } = props

  return (
    <SkeletonTextWrapper width={width} height={height} borderRadius={borderRadius} {...rest}>
      {children}
    </SkeletonTextWrapper>
  )
}

// Default Props
SkeletonText.defaultProps = {
  width: '',
  height: '',
  borderRadius: '',
  children: '',
}

// Proptypes Validation
SkeletonText.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default SkeletonText
