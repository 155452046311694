import styled from 'styled-components'

export const TableButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  gap: 50px;
  align-items: center;

  padding: 10px 20px 10px 20px;
  color: ${({ theme }) => theme.colors.darkGray};
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`

export const TableRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 20px;

  &:last-child > div:last-child {
    max-width: 150px;
  }
  &:last-child > div:first-child {
    justify-content: flex-end;
    max-width: 550px;
  }

  &:first-child > div:last-child {
    justify-content: flex-end;
    visibility: ${({ isExpanded }) => isExpanded && 'hidden'};
  }
  &:last-child > div:first-child {
    visibility: ${({ isExpanded }) => isExpanded && 'hidden'};
  }
`
export const TableRowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  gap: 20px;
`
export const TableColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableText = styled.div`
  font-size: 15px;
  font-weight: 400;

  max-width: 250px;

  &:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
export const TableSubText = styled.div`
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const TablePanelWrapper = styled.div``
