/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer } from './DemoReportsTableItem.elements'

// Views
import { TableItem, ButtonSplitSelect, SkeletonText } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function DemoReportsTableItem(props) {
  // Destructure
  const { actions, ...rest } = props
  const { toggleUpgradeModal } = actions

  return (
    <TableItem>
      <TableButtonWrapper {...rest}>
        <TableRowWrapper style={{ gridTemplateColumns: '1fr 0.7fr 2fr' }}>
          <TableRowContainer>
            <SkeletonText width="120" />
          </TableRowContainer>
          <TableRowContainer>
            <SkeletonText width="90" />
          </TableRowContainer>
          <TableRowContainer>
            <SkeletonText width="90" />
          </TableRowContainer>
        </TableRowWrapper>

        <TableRowWrapper style={{ gridTemplateColumns: '150px 1fr' }}>
          <TableRowContainer>
            <SkeletonText width="180" />
          </TableRowContainer>

          <TableRowContainer>
            <ButtonSplitSelect onClick={() => notifyPaymentRequired(toggleUpgradeModal)}>Download</ButtonSplitSelect>
          </TableRowContainer>
        </TableRowWrapper>
      </TableButtonWrapper>
    </TableItem>
  )
}

// Default Props
DemoReportsTableItem.defaultProps = {
  actions: {},
}

// Proptypes Validation
DemoReportsTableItem.propTypes = {
  actions: PropTypes.shape({
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}
export default connect(mapStateToProps, mapDispatchToProps)(DemoReportsTableItem)
