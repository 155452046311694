import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Label, Title, SubTitle, TableColWrapper, TableRowContainer } from './DemoAccountsTableItem.elements'

// Views
import { CardTableItem, Button, SkeletonText } from 'views/components'

function DemoAccountsTableItem(props) {
  // Destructure
  const { account_type_id, ...rest } = props

  return (
    <CardTableItem {...rest}>
      <TableColWrapper>
        <Title>
          ID: <SkeletonText width="120" height="24" />
        </Title>
        <SubTitle>{account_type_id === 1 ? 'INDIVIDUAL ACCOUNT' : 'BUSINESS ACCOUNT'}</SubTitle>
        <Button variant="outlined" disabled size="tiny">
          PENDING TRANSFERS
        </Button>
      </TableColWrapper>

      <TableColWrapper>
        <TableRowContainer>
          <Label>{account_type_id === 1 ? 'Name: ' : 'Company Name: '}</Label>
          <LocalSkeletonText />
        </TableRowContainer>
        <TableRowContainer>
          <Label>Country of Domicile: </Label>
          <LocalSkeletonText />
        </TableRowContainer>
        {account_type_id === 1 && (
          <TableRowContainer>
            <Label>{'Date of Birth: '} </Label>
            <LocalSkeletonText />
          </TableRowContainer>
        )}
        <TableRowContainer>
          <Label>{account_type_id === 1 ? 'Nationality: ' : 'Country of Incorporation: '} </Label>
          <LocalSkeletonText />
        </TableRowContainer>
      </TableColWrapper>

      <TableColWrapper>
        <TableRowContainer>
          <Label>Status: </Label>
          <LocalSkeletonText />
        </TableRowContainer>
        <TableRowContainer>
          <Label>Account Risk: </Label>
          <LocalSkeletonText />
        </TableRowContainer>
        <TableRowContainer>
          <Label>Open Alerts: </Label>
          <LocalSkeletonText />
        </TableRowContainer>
        <TableRowContainer>
          <Label>Transfers: </Label>
          <LocalSkeletonText />
        </TableRowContainer>
      </TableColWrapper>
    </CardTableItem>
  )
}

// Default Props
DemoAccountsTableItem.defaultProps = {}

// Proptypes Validation
DemoAccountsTableItem.propTypes = {
  account_type_id: PropTypes.number,
}

export default DemoAccountsTableItem

function LocalSkeletonText() {
  const [num] = useState(`${Math.floor(Math.random() * 80 + 30)}`)
  return <SkeletonText width={num} height="16" borderRadius="4" />
}
