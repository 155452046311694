import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// Constants
import { REPORT_ADDRESS_SORT_DATE_FILTER } from 'common/constants/filters'

// Utils
import { getCustomerStatus, notifyPaymentRequired } from 'common/utils/customerValidation'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TableFilterFormWrapper } from '../Filter.elements'

// Components
import { SimpleSelect, ButtonFilter, CustomDateRangePicker } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function ReportsAddressTableFilter(props) {
  // Destructure
  const { filters, actions } = props
  const { reportsAddressFilters } = filters
  const { sort, start_range, end_range } = reportsAddressFilters

  // Store Actions
  const { setReportsAddressFilters, toggleUpgradeModal } = actions

  // Local States
  const [csi] = useState(getCustomerStatus())

  // Functions
  // Filter Functions
  const handleDateSelect = async ({ start, end }) => {
    await setReportsAddressFilters({
      ...reportsAddressFilters,
      start_range: start,
      end_range: end,
    })
  }
  const handleSortSelect = async (value) => {
    if (sort !== value) {
      await setReportsAddressFilters({ ...reportsAddressFilters, sort: value })
    }
  }

  return (
    <TableFilterFormWrapper>
      {csi === 1 && (
        <ButtonFilter
          filter={
            <>
              <CustomDateRangePicker
                id="addressDateFilter"
                onApply={handleDateSelect}
                start={start_range}
                end={end_range}
                style={{ minWidth: '300px' }}
              />
              <SimpleSelect
                label="Sort By: "
                options={REPORT_ADDRESS_SORT_DATE_FILTER}
                onChange={handleSortSelect}
                value={sort}
              />
            </>
          }
        />
      )}

      {csi !== 1 && <ButtonFilter onClick={() => notifyPaymentRequired(toggleUpgradeModal)} filter={<div />} />}
    </TableFilterFormWrapper>
  )
}

// Default Props
ReportsAddressTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
ReportsAddressTableFilter.propTypes = {
  filters: PropTypes.shape({
    reportsAddressFilters: PropTypes.shape({
      sort: PropTypes.string,
      account_type_id: PropTypes.number,
      page_index: PropTypes.number,
      start_range: PropTypes.string,
      end_range: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setReportsAddressFilters: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsAddressTableFilter)
