import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalBodyWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 100%;
  max-width: 350px;
  min-height: 550px;
  height: 100%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 0 10px 10px 0;
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 0 0 0px 0;
`
export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0 30px 0;
`
export const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  width: 100%;

  & a {
    width: 100%;
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue};

  & button {
    min-height: 56px;
    margin: 20px 0px 0px 0px;
  }
`
